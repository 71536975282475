import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Layout from '../components/layout';
import Img from 'gatsby-image';
import HeaderImage from '../components/modules/header-image/headerImage';


class FeaturedPostTemplate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			items: []
		}
	}
	render() {
		const siteTitle = get(this, 'props.data.site.siteMetadata.title')
		const post = get(this.props, 'data.contentfulFeaturedPost')
		const [menu] = get(this, 'props.data.allContentfulMainMenu.edges')
		const [footer] = get(this, 'props.data.allContentfulFooterMenu.edges')
		const images = Array.isArray(post.images) ? post.images.map(i => <div className='item' key={i.id}><Img
			className={i.title}
			alt={i.title}
			fluid={i.fluid}
			title={i.description}
		/></div>) : []

		return (
			<Layout
				title={post.metaTitle ? post.metaTitle : siteTitle}
				metaDescription={post.metaDescription.metaDescription}
				menuItems={menu.node.menuItems}
				brandLogo={menu.node.brandLogo.file.url}
				location={this.props.location}
				footerItems={footer.node.footerItems}
				linkedIn={footer.node.linkedIn}
				facebook={footer.node.facebook}
				youtube={footer.node.youtube}
				instagram={footer.node.instagram}
				brandLogoFooter={footer.node.brandLogoFooter.file.url}
			>
				<HeaderImage image={post.featuredImage} title={post.titleAnimation} strokeDasharray={post.strokeDasharray} />
				<div className='featured-post'>
					<div className="wrapper">
						<div className='intro'
							dangerouslySetInnerHTML={{
								__html: post.textIntro.childMarkdownRemark.html,
							}}
						/>
						<div
							dangerouslySetInnerHTML={{
								__html: post.textTop.childMarkdownRemark.html,
							}}
						/>
						<div className='images'>
							{images}
						</div>
						
						<div
							dangerouslySetInnerHTML={{
								__html: post.textBottom.childMarkdownRemark.html,
							}}
						/>
						{post.featuredVideo ?
							<video id="video" controls="controls" loop muted>
								<source src={post.featuredVideo.file.url} type='video/mp4' />
							</video>: ''}
					</div>
				</div>
			</Layout>
		)
	}
}

export default FeaturedPostTemplate

export const pageQuery = graphql`
	query FeaturedPostBySlug($slug: String!) {
		site {
			siteMetadata {
				title
			}
		}
		allContentfulMainMenu(filter: {title: {eq: "Hoofdmenu"}}) {
			edges {
				node {
					title
					menuItems {
						title
						url
						subItems {
							title
							url
						}
					}
					brandLogo {
						title
						description
						file {
							url
						}
					}
				}
			}
		}
		allContentfulFooterMenu(filter: {title: {eq: "Footer"}}) {
			edges {
				node {
  					...Footer
        		}
      		}
		}
		contentfulFeaturedPost(slug: { eq: $slug }) {
			title
			metaTitle
			titleAnimation
			strokeDasharray
			metaDescription {
				metaDescription
			}
			featuredImage {
				title
				description
					fluid(quality: 100, maxWidth: 2000, toFormat: JPG) {
						src
					}
				}
			textIntro {
				childMarkdownRemark {
					html
				}
			}
			textTop {
				childMarkdownRemark {
					html
				}
			}
			textBottom {
				childMarkdownRemark {
					html
				}
			}
			images {
				id
				title
				description
				fluid {
					src
				}
			}
			featuredVideo {
				file {
					url
				}
			}
		}
	}
`